import {INVALID_AUTH_TOKEN} from "src/config.js";

export const validateAuth0TokenExpiryAndGetUserInfo = (token) => {
  const [, encodedPayload] = token.split('.');
  const payload = JSON.parse(atob(encodedPayload));

  if (Date.now() >= new Date(payload.exp * 1000).getTime()) throw new Error(INVALID_AUTH_TOKEN);

  return payload;
};
