import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

import commonRouteConfig from 'src/routes';
import memberPendingApprovalRouteConfig from 'src/routes/member-pending-approval';
import superAdminRouteConfig from 'src/routes/super-admin';
import adminRouteConfig from 'src/routes/admin';
import adminViewOnlyRouteConfig from 'src/routes/admin-view-only';
import memberAdminRouteConfig from 'src/routes/member-admin';
import memberRouteConfig from 'src/routes/member';

import { USER_ROLE } from 'src/config';
import { useAuth } from './use-auth';
import { isActive } from 'src/utils/common';

const ROLE_ROUTE_MAP = {
  [USER_ROLE.superAdmin]: superAdminRouteConfig,
  [USER_ROLE.admin]: adminRouteConfig,
  [USER_ROLE.adminViewOnly]: adminViewOnlyRouteConfig,
  [USER_ROLE.memberAdmin]: memberAdminRouteConfig,
  [USER_ROLE.member]: memberRouteConfig,
};

const useSentryRoutes = wrapUseRoutes(useRoutes);

export const useAppRoutes = () => {
  const { user } = useAuth();

  const userRouteConfig = useMemo(() => {
    let routeConfig;

    if (user) {
      routeConfig = memberPendingApprovalRouteConfig;

      if (isActive(user.company) && user.role?.role in ROLE_ROUTE_MAP) {
        routeConfig = ROLE_ROUTE_MAP[user.role.role];
      }
    }

    return routeConfig?.length
      ? [commonRouteConfig[0], ...routeConfig, ...commonRouteConfig.slice(1)]
      : commonRouteConfig;
  }, [user]);

  return useSentryRoutes(userRouteConfig);
};
