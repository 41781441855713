import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { paths } from 'src/paths';

const PracticeSettingsPage = lazy(() =>
  import('src/pages/dashboard/member-admin/practice-settings'),
);

const DashboardPage = lazy(() => import('src/pages/dashboard/cms'));

const CreateGPO = lazy(() =>
  import('src/pages/dashboard/member-admin/gpo/create'),
);

const CMSListingPage = lazy(() =>
  import('src/pages/dashboard/cms/category-index'),
);
const CMSDetailPage = lazy(() =>
  import('src/pages/dashboard/cms/category-item-detail'),
);

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

const routeConfig = [
  {
    path: '/dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: paths.dashboard.cms.listing,
        element: <CMSListingPage />,
      },
      {
        path: paths.dashboard.cms.detail,
        element: <CMSDetailPage />,
      },
      {
        path: paths.dashboard.cms.article,
        element: <CMSDetailPage />,
      },
      {
        path: paths.dashboard.practice,
        element: <PracticeSettingsPage />,
      },
      {
        path: 'gpo',
        children: [
          {
            path: 'create/:gpoId',
            element: <CreateGPO />,
          },
        ],
      },

      {
        path: 'account',
        element: <AccountPage />,
      },
    ],
  },
];

export default routeConfig;

export const memberAdminRoutes = [
  paths.dashboard.index,

  // paths.dashboard.member.index,
  // `^${paths.dashboard.member}/[^/]+$`,
  // `^${paths.dashboard.member}/[^/]+/edit$`,

  `^${paths.dashboard.location}/[^/]+/edit$`,

  paths.dashboard.gpo.index,
  paths.dashboard.gpo.create,

  paths.dashboard.account,
];
