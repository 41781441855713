import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { paths } from 'src/paths';

const PreApprovalPage = lazy(() => import('src/pages/dashboard/pre-approval'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

const routeConfig = [
  {
    path: '/dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <PreApprovalPage />,
      },
      {
        path: 'account',
        element: <AccountPage />,
      },
    ],
  },
];

export const memberPendingApprovalRoutes = [
  paths.dashboard.index,
  paths.dashboard.account,
];

export default routeConfig;
