import { lazy } from 'react';
import { AuthIndex } from 'src/pages/auth';

const SignupPage = lazy(() => import('src/pages/auth/signup'));
const LoginPage = lazy(() => import('src/pages/auth/login'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password'));
const MemberIntakePage = lazy(() => import('src/pages/auth/member-intake'));

const routeConfig = [
  {
    path: '/auth',
    children: [
      {
        path: '',
        element: <AuthIndex />,
        children: [
          {
            path: 'signup',
            element: <SignupPage />,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/member-intake',
    children: [
      {
        path: '',
        element: <AuthIndex />,
        children: [
          {
            path: 'company/:companyId/:token',
            element: <MemberIntakePage />,
          },
        ],
      },
    ],
  },
];

export default routeConfig;
