import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { RTL } from 'src/components/rtl';
import { SplashScreen } from 'src/components/splash-screen';
import { Toaster } from 'src/components/toaster';

import { AuthConsumer, AuthProvider } from 'src/contexts/auth/jwt';
import { SettingsConsumer, SettingsProvider } from 'src/contexts/settings';
import { CommonContextProvider } from 'src/contexts/common';
import { CustomThemeContext } from 'src/contexts/custom-theme';

import { useNprogress } from 'src/hooks/use-nprogress';
import { useAnalytics } from 'src/hooks/use-analytics';
import { useAppRoutes } from 'src/hooks/use-app-routes';
import { createTheme } from 'src/theme';

import 'src/global.css';

export const App = () => {
  useAnalytics();
  useNprogress();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AuthConsumer>
          {auth => (
            <CommonContextProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {settings => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                      // return null;
                    }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes,
                    });

                    // Prevent guards from redirecting
                    const showSlashScreen = !auth.isInitialized;

                    return (
                      <ThemeProvider theme={theme}>
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          {showSlashScreen ? <SplashScreen /> : <Page />}
                          <Toaster />
                        </RTL>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </CommonContextProvider>
          )}
        </AuthConsumer>
      </AuthProvider>
    </LocalizationProvider>
  );
};

const Page = () => {
  const children = useAppRoutes();

  const { palette } = useTheme();

  const values = useMemo(
    function () {
      const fieldsetStyle = {
        borderColor: palette.primary.main,
        borderWidth: 2,
        color: palette.primary.main,
      };

      const inputStyle = {
        borderWidth: 2,
        borderColor: palette.primary.main,
      };

      // const labelStyle = {
      //   color: palette.primary.main,
      // };

      return {
        outlinedInputStyle: {
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
            fieldsetStyle,
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            fieldsetStyle,
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            fieldsetStyle,
          // '& .MuiInputLabel-shrink': labelStyle,
        },
        textFieldStyle: {
          // '& label.MuiInputLabel-shrink': labelStyle,
          '& .MuiInputBase-root': {
            '&': inputStyle,
          },
        },
      };
    },
    [palette.primary.main],
  );

  return (
    <CustomThemeContext.Provider value={values}>
      {children}
    </CustomThemeContext.Provider>
  );
};
