import { DIRECTUS_API_URL } from 'src/config';

const ENDPOINT = `${DIRECTUS_API_URL}/items`;

export const getCategoriesList = () =>
  fetch(`${ENDPOINT}/categories`).then(res => res.json());
export const getArticleList = () =>
  fetch(`${ENDPOINT}/article_list`).then(res => res.json());
export const getArticleDetails = id =>
  fetch(`${ENDPOINT}/article/${id}`).then(res => res.json());

export const getEventList = () =>
  fetch(`${ENDPOINT}/events?filter[status][_eq]=published`).then(res =>
    res.json(),
  );

export const getBanners = () =>
  fetch(`${ENDPOINT}/banner`).then(res => res.json());

export const getPageBreakOrBanners = () =>
  fetch(`${ENDPOINT}/page_banner`).then(res => res.json());
