import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { paths } from 'src/paths';

const DashboardPage = lazy(() => import('src/pages/dashboard/cms'));

const CMSListingPage = lazy(() =>
  import('src/pages/dashboard/cms/category-index'),
);
const CMSDetailPage = lazy(() =>
  import('src/pages/dashboard/cms/category-item-detail'),
);

const PracticeSettingsPage = lazy(() =>
  import('src/pages/dashboard/member-admin/practice-settings'),
);

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

const routeConfig = [
  {
    path: '/dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: paths.dashboard.cms.listing,
        element: <CMSListingPage />,
      },
      {
        path: paths.dashboard.practice,
        element: <PracticeSettingsPage />,
      },
      {
        path: paths.dashboard.cms.detail,
        element: <CMSDetailPage />,
      },
      {
        path: paths.dashboard.cms.article,
        element: <CMSDetailPage />,
      },
      {
        path: 'account',
        element: <AccountPage />,
      },
    ],
  },
];

export default routeConfig;

export const memberRoutes = [paths.dashboard.index, paths.dashboard.account];
