import {Box, Typography} from '@mui/material';

import {Logo} from 'src/components/logo';

export const AuthModernLayout = (props) => {
  const {children} = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'neutral.800',
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto',
          },
          justifyContent: 'center',
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography
            sx={{mb: 1}}
            variant="h2"
          >
            Welcome to &nbsp;
            <Box sx={{display: 'inline-block', width: 150, verticalAlign: 'top', mt: 1}}>
              <Logo mode="dark"/>
            </Box>
          </Typography>
          <Typography
            color="text.secondary"

          >
            You care for your patients, we care for your practice. We deliver solutions for the
            independent physician that transform practice profitability, reduce risk, preserve
            autonomy and save time.
          </Typography>

        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto',
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center',
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
