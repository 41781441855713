import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Drawer, Stack, SvgIcon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Email, Phone } from '@mui/icons-material';
import { Building02 } from '@untitled-ui/icons-react';

import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { Scrollbar } from 'src/components/scrollbar';
import { TenantSwitch } from '../tenant-switch';
import { MobileNavSection } from './mobile-nav-section';

import { usePathname } from 'src/hooks/use-pathname';
import { useAuth } from 'src/hooks/use-auth';

import { paths } from 'src/paths';
import {
  isAdmin,
  isAdminViewOnly,
  isMember,
  isMemberAdmin,
  isSuperAdmin,
} from 'src/utils/common';
import { CONTACT_EMAIL, CONTACT_PHONE, OMO_STORE_URL } from 'src/config';

const MOBILE_NAV_WIDTH = 280;

const useCssVars = function (color) {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      // Blend-in and discrete have no difference on mobile because
      // there's a backdrop and differences are not visible
      case 'blend-in':
      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          };
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const MobileNav = function ({
  color = 'discrete',
  open,
  onClose,
  sections = [],
}) {
  const { user } = useAuth();
  const pathname = usePathname();
  const [sideNavColor, setSideNavColor] = useState(color);
  const cssVars = useCssVars(sideNavColor);

  const isAdminView =
    isAdminViewOnly(user) || isAdmin(user) || isSuperAdmin(user);

  useEffect(() => {
    setSideNavColor(isAdminView ? 'evident' : 'discrete');
  }, [isAdminView]);

  const isMemberOrMemberAdmin = isMember(user) || isMemberAdmin(user);

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: MOBILE_NAV_WIDTH,
        },
      }}
      variant="temporary"
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
            <Box
              component={RouterLink}
              href={paths.index}
              sx={{
                borderColor: 'var(--nav-logo-border)',
                borderRadius: 1,
                borderStyle: 'solid',
                borderWidth: 1,
                display: 'flex',
                height: 40,
                p: '4px',
                width: 40,
              }}
            >
              <Logo mode={'light'} />
            </Box>
            <TenantSwitch sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}
          >
            {sections.map((section, index) => (
              <MobileNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}

            {isMemberOrMemberAdmin &&
            user.company.serviceStatus === 'active' &&
            user.company.isConsent ? (
              <Button
                component="a"
                href={OMO_STORE_URL}
                startIcon={
                  <SvgIcon>
                    <Building02 />
                  </SvgIcon>
                }
                target="_blank"
                color="secondary"
                variant="contained"
              >
                Shop
              </Button>
            ) : null}
          </Stack>

          {isMemberOrMemberAdmin ? (
            <Box sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Typography variant="h6">Help?</Typography>
                <Button
                  component="a"
                  fullWidth
                  href={`tel:${CONTACT_PHONE}`}
                  startIcon={
                    <SvgIcon>
                      <Phone />
                    </SvgIcon>
                  }
                  target="_blank"
                  variant="outlined"
                >
                  877-784-2656
                </Button>
                <Button
                  component="a"
                  fullWidth
                  href={`mailto:${CONTACT_EMAIL}`}
                  startIcon={
                    <SvgIcon>
                      <Email />
                    </SvgIcon>
                  }
                  target="_blank"
                  variant="outlined"
                >
                  Email us
                </Button>
              </Stack>
            </Box>
          ) : null}
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

MobileNav.propTypes = {
  color: PropTypes.oneOf(['blend-in', 'discrete', 'evident']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};
