import { get, post, put } from 'src/api';
import {
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGIN,
  AUTH_MEMBER_SIGNUP,
  AUTH_RESET_PASSWORD,
} from 'src/config';

const USER_ENDPOINT = 'users';

export const login = request => post(AUTH_LOGIN, request);

export const register = request => post(AUTH_MEMBER_SIGNUP, request);

export const resetPass = email => post(AUTH_RESET_PASSWORD, { email });

export const changePass = password => post(AUTH_CHANGE_PASSWORD, { password });

export const getUserInfo = () => get(`${USER_ENDPOINT}/get-profile`);

export const updateProfile = values =>
  put(`${USER_ENDPOINT}/update-profile`, values);
