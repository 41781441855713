import {
  __DEV__,
  DIRECTUS_API_URL,
  HUBSPOT_LOCATION_OBJECT_ID,
  HUBSPOT_PORTAL_ID,
  USER_ROLE,
} from 'src/config';
import { uploadBlob } from 'src/api/common';

export const getEmailRegex = () =>
  new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  );

export const getPhoneRegex = () =>
  new RegExp('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$');

export const getPasswordRegex = () =>
  new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$');

export const getZipCodeRegex = () => new RegExp('^\\d{5}(-\\d{4})?');

export const getTaxEINRegex = () => new RegExp('^\\d{2}-\\d{7}$');

export const getCityRegex = () => new RegExp('^[a-zA-Z]+(?:[\\s-][a-zA-Z]+)*$');

export const fnTransformTrim = value => value?.trim?.() ?? '';

export const getErrorMessage = (error, message, enableRawError = false) => {
  const devErrorMessage = error.response?.data?.message
    ? Array.isArray(error.response.data.message)
      ? error.response.data.message.join('\n')
      : error.response.data.message
    : error.message ?? message;

  // captureException(devErrorMessage, {
  //   extra: {
  //     error,
  //   },
  // });

  return __DEV__ || enableRawError ? devErrorMessage : message;
};

// format phone number to +1 (xxx) xxx-xxxx
export const formatPhone = phone => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `+1 (${match[1]}) ${match[2]}-${match[3]}` : null;
};

export const formatDate = date => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'short' });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

export const isSuperAdmin = user => USER_ROLE.superAdmin === user?.role?.role;
export const isAdmin = user => USER_ROLE.admin === user?.role?.role;
export const isAdminViewOnly = user =>
  USER_ROLE.adminViewOnly === user?.role?.role;
export const isMemberAdmin = user => USER_ROLE.memberAdmin === user?.role?.role;
export const isMember = user => USER_ROLE.member === user?.role?.role;

export const isActive = item => item?.serviceStatus === 'active';
export const isInActive = item => item?.serviceStatus === 'inactive';

export const canvasToResizedBlob = (canvas, maxWidth = 300, maxHeight = 45) =>
  // Convert signature to blob and resize
  new Promise(function (resolve, reject) {
    // Calculate the new dimensions while maintaining the aspect ratio
    let width = canvas?.width || 0;
    let height = canvas?.height || 0;

    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }

    // Create a temporary canvas to resize the image
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;

    const context = tempCanvas.getContext('2d');

    if (context) {
      // Draw the original image on the temporary canvas with the new dimensions
      context.drawImage(canvas, 0, 0, width, height);

      // Convert the resized image on the temporary canvas to a blob
      tempCanvas.toBlob(function (blob) {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Could not convert signature to blob'));
        }
      });
    } else {
      reject(new Error('Could not create canvas context'));
    }
  });

export const getCanvasFromImageURL = url =>
  new Promise(function (resolve, reject) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth || img.width;
      canvas.height = img.naturalHeight || img.height;

      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(img, 0, 0);
        resolve(canvas);
      } else {
        reject(new Error('Could not create canvas context'));
      }
    };
    img.onerror = reject;

    img.src = url;
  });

export const getWebURLFromDataURL = (name, url) =>
  getCanvasFromImageURL(url)
    .then(canvas => canvasToResizedBlob(canvas))
    .then(blob => uploadBlob(name, blob));

export const toISOString = function (date) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date - tzoffset).toISOString().slice(0, -1);
};

export const getToggledStatusAndOp = item =>
  item
    ? {
        statusToChange: item.serviceStatus === 'active' ? 'inactive' : 'active',
        statusToChangeOp:
          item.serviceStatus === 'active' ? 'Deactivate' : 'Activate',
      }
    : {
        statusToChange: '',
        statusToChangeOp: '',
      };

export const getHubspotContactURL = hubspotContactId =>
  hubspotContactId
    ? `https://app.hubspot.com/contacts/${HUBSPOT_PORTAL_ID}/record/0-1/${hubspotContactId}`
    : '';

export const getHubspotCompanyURL = hubspotCompanyId =>
  hubspotCompanyId
    ? `https://app.hubspot.com/contacts/${HUBSPOT_PORTAL_ID}/record/0-2/${hubspotCompanyId}`
    : '';

export const getHubspotLocationURL = hubspotLocationId =>
  hubspotLocationId
    ? `https://app.hubspot.com/contacts/${HUBSPOT_PORTAL_ID}/record/${HUBSPOT_LOCATION_OBJECT_ID}/${hubspotLocationId}`
    : '';

export const addScript = (id, src, onLoad) =>
  new Promise(function (resolve, reject) {
    const presentScript = document.getElementById(id);

    if (presentScript) {
      resolve(presentScript);
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.async = true;
    script.defer = true;
    script.onload = function () {
      onLoad?.();
      resolve(script);
    };
    script.onerror = function () {
      document.body.removeChild(script);
      reject(new Error(`Failed to load script with src: ${src}`));
    };
    script.src = src;
    document.body.appendChild(script);

    return script;
  });

export const addAskAnythingEmbedCode = () =>
  addScript('hs-script-loader', 'https://js-na1.hs-scripts.com/8929656.js');

export const addHubspotFormsEmbedCode = () =>
  addScript('hs-forms-embed-code', '//js.hsforms.net/forms/embed/v2.js');

export const isOptionEqualToValue = (option, value) =>
  option?.value === value?.value;

export const getDirectUsImageURL = image =>
  `${DIRECTUS_API_URL}/assets/${image}`;
