import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';

import { usePopover } from 'src/hooks/use-popover';
import { useAuth } from 'src/hooks/use-auth.js';

import { TenantPopover } from './tenant-popover';

export const TenantSwitch = props => {
  const popover = usePopover();
  const [companyList, setCompanyList] = useState([]);
  const { user } = useAuth();

  const tenants = useEffect(() => {
    // TODO: fetch companies and set it in `companyList`
  }, [user?.role?.role]);

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="inherit" variant="h6">
            omo
          </Typography>
          <Typography color="neutral.400" variant="body2">
            Power your practice
          </Typography>
        </Box>
        {companyList?.length ? (
          <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
            <SvgIcon sx={{ fontSize: 16 }}>
              <ChevronDownIcon />
            </SvgIcon>
          </IconButton>
        ) : null}
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={companyList}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
