import { get } from 'src/api';
import { __DEV__ } from 'src/config.js';

export const getUserTypesMasterList = () => get('user-types');
export const getClassOfTradeOptions = () => get('class-of-trade');
export const getMedicalDegrees = () => get('medical-degree');
export const getSpecialty = () => get('medical-specialty');
export const getCompanySpecialty = () => get('specialty');
export const getBusinessEntityTypes = () => get('business-entity-type');
export const getUserRoles = () => get('roles/list');

export const generateUrls = fileName =>
  get(`s3/generate-signed-url?fileName=${encodeURIComponent(fileName)}`);

export const uploadFile = (prefix, file) =>
  new Promise(function (resolve, reject) {
    if (file instanceof File) {
      if (file.type === 'application/pdf') {
        let fileName = file.name.trim().replace(/\s+/g, '_').split('.');
        fileName.pop();
        fileName = fileName.join('.') + '_' + Date.now() + '.pdf';

        const name = prefix + '/' + fileName;

        generateUrls(name).then(function ({ signedUrl, publicUrl }) {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', signedUrl, true);
          xhr.setRequestHeader('Content-Type', file.type);

          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 400) {
              resolve({ publicUrl, name });
            } else {
              reject(
                new Error(
                  __DEV__ ? xhr.responseText : 'Could not upload file.',
                ),
              );
            }
          };

          xhr.onerror = function () {
            reject(new Error('Upload error'));
          };

          xhr.send(file);
        });
      } else {
        reject(new Error('PDF file is required.'));
      }
    } else {
      reject(new Error('Please provide a valid file object.'));
    }
  });

export const uploadBlob = (name, blob) =>
  new Promise(function (resolve, reject) {
    if (blob instanceof Blob) {
      let fileName = name.trim().replace(/\s+/g, '_').split('.');
      fileName.pop();
      fileName =
        fileName.join('.') +
        '_' +
        Date.now() +
        '.' +
        blob.type.replace('image/', '');

      generateUrls(fileName).then(function ({ signedUrl, publicUrl }) {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', blob.type);

        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 400) {
            resolve({ publicUrl, name: fileName });
          } else {
            reject(
              new Error(__DEV__ ? xhr.responseText : 'Could not upload file.'),
            );
          }
        };

        xhr.onerror = function () {
          reject(new Error('Upload error'));
        };

        xhr.send(blob);
      });
    } else {
      reject(new Error('Please provide a valid blob object.'));
    }
  });
