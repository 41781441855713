import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { paths } from 'src/paths';

const DashboardPage = lazy(() =>
  import('src/pages/dashboard/super-admin/dashboard'),
);

// Company
const CompanyList = lazy(() => import('src/pages/dashboard/company/list'));
const MemberList = lazy(() => import('src/pages/dashboard/member/list'));
const CompanyDetailPage = lazy(() =>
  import('src/pages/dashboard/company/detail'),
);

const CreateGPO = lazy(() =>
  import('src/pages/dashboard/member-admin/gpo/create'),
);

const AdminList = lazy(() =>
  import('src/pages/dashboard/super-admin/admin-list'),
);
// logs
const LogsList = lazy(() => import('src/pages/dashboard/omo-admin-logs'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

const routeConfig = [
  {
    path: '/dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: 'company',
        children: [
          {
            index: true,
            element: <CompanyList />,
          },
          {
            path: ':companyId',
            element: <CompanyDetailPage />,
          },
        ],
      },
      {
        path: 'member',
        children: [
          {
            index: true,
            element: <MemberList />,
          },
        ],
      },
      {
        path: 'gpo',
        children: [
          {
            path: 'create/:gpoId',
            element: <CreateGPO />,
          },
        ],
      },

      {
        path: 'account',
        element: <AccountPage />,
      },

      {
        path: 'admin-list',
        element: <AdminList />,
      },
      {
        path: 'activity-logs',
        children: [
          {
            index: true,
            element: <LogsList />,
          },
        ],
      },
    ],
  },
];

export default routeConfig;

export const superAdminRoutes = [
  paths.dashboard.index,

  paths.dashboard.company.index,
  `^${paths.dashboard.company}/[^/]+$`,

  paths.dashboard.member.index,

  paths.dashboard.account,
];
