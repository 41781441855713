import { useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import { UsersPlus } from '@untitled-ui/icons-react';
import { AutoAwesomeMosaic, ListAlt } from '@mui/icons-material';

import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import Building04Icon from 'src/icons/untitled-ui/duocolor/building-04';

import { useCommonContext } from 'src/contexts/common';

import { useAuth } from 'src/hooks/use-auth';

import { paths } from 'src/paths';
import {
  getDirectUsImageURL,
  isActive,
  isMember,
  isMemberAdmin,
} from 'src/utils/common';
import { COMING_SOON_ICON, COMING_SOON_URL_HASH } from 'src/config';

const navMap = {
  superAdmin: [
    {
      title: 'Companies',
      path: paths.dashboard.company.index,
      icon: (
        <SvgIcon fontSize="small">
          <Building04Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'Members',
      path: paths.dashboard.member.index,
      icon: (
        <SvgIcon fontSize="small">
          <Users03Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'omo Admins',
      path: paths.dashboard.adminList,
      icon: (
        <SvgIcon fontSize="small">
          <UsersPlus />
        </SvgIcon>
      ),
    },
    {
      title: 'Activity Logs',
      path: paths.dashboard.logs,
      icon: (
        <SvgIcon fontSize="small">
          <ListAlt />
        </SvgIcon>
      ),
    },
  ],
  admin: [
    {
      title: 'Companies',
      path: paths.dashboard.company.index,
      icon: (
        <SvgIcon fontSize="small">
          <Building04Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'Members',
      path: paths.dashboard.member.index,
      icon: (
        <SvgIcon fontSize="small">
          <Users03Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'Activity Logs',
      path: paths.dashboard.logs,
      icon: (
        <SvgIcon fontSize="small">
          <ListAlt />
        </SvgIcon>
      ),
    },
  ],
  adminViewOnly: [
    {
      title: 'Companies',
      path: paths.dashboard.company.index,
      icon: (
        <SvgIcon fontSize="small">
          <Building04Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'Members',
      path: paths.dashboard.member.index,
      icon: (
        <SvgIcon fontSize="small">
          <Users03Icon />
        </SvgIcon>
      ),
    },
    {
      title: 'Activity Logs',
      path: paths.dashboard.logs,
      icon: (
        <SvgIcon fontSize="small">
          <ListAlt />
        </SvgIcon>
      ),
    },
  ],
  memberAdmin: [],
  member: [],
};

export const useSections = () => {
  const { user } = useAuth();
  const { categories, articles } = useCommonContext();

  return useMemo(() => {
    const isMemberOrMemberAdmin = isMember(user) || isMemberAdmin(user);

    const hasCompanyBeenActivated = isActive(user.company);

    // add CMS links only for `member/member admin` who are `active`
    const memberCMSLinks =
      isMemberOrMemberAdmin && hasCompanyBeenActivated
        ? categories?.map(category => ({
            title: category.name,
            path: paths.dashboard.cms.listing.replace(
              ':categoryId',
              category.id,
            ),
            icon: (
              <img
                src={getDirectUsImageURL(category.icon)}
                width={20}
                alt={category.name}
              />
            ),
          })) ?? []
        : [];

    if (
      memberCMSLinks?.length &&
      Boolean(articles?.some(article => Boolean(article.coming_soon)))
    ) {
      memberCMSLinks.push({
        title: 'Coming Soon',
        path: paths.dashboard.index + '#' + COMING_SOON_URL_HASH,
        icon: (
          <img
            src={getDirectUsImageURL(COMING_SOON_ICON)}
            width={20}
            alt="Coming Soon"
          />
        ),
      });
    }

    return user?.role?.role
      ? [
          {
            items: [
              ...(hasCompanyBeenActivated
                ? [
                    {
                      title: 'Home',
                      path: paths.dashboard.index,
                      icon: (
                        <SvgIcon fontSize="small">
                          <HomeSmileIcon />
                        </SvgIcon>
                      ),
                    },
                  ]
                : []),

              ...memberCMSLinks,

              ...(hasCompanyBeenActivated &&
              isMemberOrMemberAdmin &&
              user.company.isConsent
                ? [
                    {
                      title: 'Member Profile',
                      path: paths.dashboard.practice,
                      icon: (
                        <SvgIcon fontSize="small">
                          <AutoAwesomeMosaic />
                        </SvgIcon>
                      ),
                    },
                  ]
                : []),

              ...(hasCompanyBeenActivated && user.role.role in navMap
                ? navMap[user.role.role]
                : []),
            ],
          },
        ]
      : [];
  }, [user, categories, articles]);
};
