export const paths = {
  index: '/',
  membership: {
    index: '/membership',
  },
  about: {
    index: '/about',
    team: '/about#team',
    events: '/events',
    carrersHub: '/careershub',
    contact: '/contact',
  },
  solutions: {
    index: '/solutions',
    financial: '/solutions/financial',
    people: '/solutions/people',
    technology: '/solutions/technology',
    wellBeing: '/solutions/well-being',
  },
  auth: {
    signup: '/auth/signup',
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
  },
  dashboard: {
    index: '/dashboard',
    overview: '/dashboard/overview',
    account: '/dashboard/account',
    onboarding: '/dashboard/onboarding',

    practice: '/dashboard/practice-settings',

    cms: {
      listing: '/dashboard/cms/:categoryId',
      detail: '/dashboard/cms/:categoryId/article/:articleId',
      article: '/dashboard/cms/article/:articleId',
    },

    adminList: '/dashboard/admin-list',
    logs: '/dashboard/activity-logs',

    member: {
      index: '/dashboard/member',
      details: '/dashboard/member/:memberId',
      edit: '/dashboard/member/:memberId/edit',
    },
    company: {
      index: '/dashboard/company',
      details: '/dashboard/company/:companyId',
      edit: '/dashboard/company/:companyId/edit',
    },
    location: {
      index: '/dashboard/location',
      details: '/dashboard/location/:locationId/details',
      edit: '/dashboard/location/:locationId/edit',
    },
    gpo: {
      index: '/dashboard/gpo',
      create: '/dashboard/gpo/create/:gpoId',
    },
  },
  subscription: {
    success: '/subscription/success',
    error: '/subscription/error',
  },
  docs: '/dashboard/docs',
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500',
};
