import { useEffect } from 'react';
// import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';

export function useAnalytics() {
  const location = useLocation();
  useEffect(() => {
    // posthog.capture('route_load');
  }, [location]);
}
