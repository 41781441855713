import { lazy } from 'react';
import authRouteConfig from './auth';

const Error401Page = lazy(() => import('src/pages/401'));
const Error404Page = lazy(() => import('src/pages/404'));
const Error500Page = lazy(() => import('src/pages/500'));

const HomePage = lazy(() => import('src/pages/index'));

// subscription response pages
const SubscriptionSuccessPage = lazy(() =>
  import('src/pages/subscription/success'),
);
// subscription response pages
const SubscriptionErrorPage = lazy(() =>
  import('src/pages/subscription/error'),
);

const routeConfig = [
  {
    path: '/',
    element: <HomePage />,
  },
  ...authRouteConfig,
  {
    path: '/subscription/success/:checkoutSessionId',
    element: <SubscriptionSuccessPage />,
  },
  {
    path: '/subscription/error',
    element: <SubscriptionErrorPage />,
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];

export default routeConfig;
