import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from '@sentry/react';

import ErrorPage from 'src/pages/500';
import { App } from 'src/app';

import '../sentry.config';
// import '../posthog';

const root = createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ErrorBoundary onError={ErrorPage}>
          <App />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
