import {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";

import {GuestGuard} from 'src/guards/guest-guard';
import {useRouter} from "src/hooks/use-router.js";
import {AuthModernLayout as AuthLayout} from 'src/layouts/auth/modern-layout';
import {paths} from "src/paths.js";

export const AuthIndex = () => {
  const location = useLocation()
  const {replace} = useRouter()

  useEffect(() => {
    if (location.pathname === '/auth') {
      replace(paths.auth.login);
    }
  }, [location.pathname]);


  return (
    <GuestGuard>
      <AuthLayout>
        <Outlet/>
      </AuthLayout>
    </GuestGuard>
  )
}
