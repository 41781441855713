import axios from 'axios';

import {
  API_ROOT,
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGIN,
  AUTH_MEMBER_SIGNUP,
  AUTH_RESET_PASSWORD,
  AUTH_STORAGE_KEY,
} from 'src/config';

const api = axios.create({
  baseURL: API_ROOT,
});

const AUTH_ENDPOINTS = [
  AUTH_LOGIN,
  AUTH_MEMBER_SIGNUP,
  AUTH_RESET_PASSWORD,
  AUTH_CHANGE_PASSWORD,
];

api.interceptors.request.use(
  config => {
    if (AUTH_ENDPOINTS.some(endpoint => config.url.includes(endpoint)))
      return config;

    const authData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || '{}');

    if (authData.accessToken) {
      config.headers['Authorization'] = `Bearer ${authData.accessToken}`;
    }
    if (authData.refreshToken) {
      config.headers['Refresh-Token'] = authData.refreshToken;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    const authData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || '{}');

    if (response.data.accessToken || response.data.refreshToken) {
      Object.keys(response.data).forEach(property => {
        authData[property] = response.data[property];
      });
    } else {
      if (response.headers['Authorization']) {
        authData.accessToken = response.headers['Authorization']
          .trim()
          .split(' ')
          .pop();
      }

      if (response.headers['Refresh-Token']) {
        authData.refreshToken = response.headers['Refresh-Token'];
      }
    }

    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authData));

    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;

export const get = (...props) => api.get(...props).then(res => res.data);
export const post = (...props) => api.post(...props).then(res => res.data);
export const put = (...props) => api.put(...props).then(res => res.data);
export const del = (...props) => api.delete(...props).then(res => res.data);
