import { Helmet } from 'react-helmet-async';

export const Seo = props => {
  const { title } = props;

  const fullTitle = title ? title + ' | omo' : 'omo';

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};
