import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { useAuth } from 'src/hooks/use-auth';

import {
  getBusinessEntityTypes,
  getClassOfTradeOptions,
  getCompanySpecialty,
  getMedicalDegrees,
  getSpecialty,
  getUserRoles,
  getUserTypesMasterList,
} from 'src/api/common';
import {
  getArticleList,
  getBanners,
  getCategoriesList,
  getEventList,
  getPageBreakOrBanners,
} from 'src/api/directus';
import { getOnBoardedCompany, getSignedConsentList } from 'src/api/onboarding';

import { isActive, isMember, isMemberAdmin } from 'src/utils/common';

const CommonContext = createContext({
  userTypes: null,
  classOfTradeOptions: null,
  medicalDegrees: null,
  specialty: null,
  companySpecialty: null,
  businessEntityTypes: null,
  roles: null,
  articleFilter: null,
  PHYSICIAN_TYPE_ID: 0,
  OTHER_TYPE_ID: 0,

  categories: null,
  articles: null,

  isProspect: true,
  company: null,
  consents: null,
  consentList: null,

  setCompany: () => {},
  getConsents: () => {},
  setArticleFilter: () => {},
});

export const useCommonContext = () => useContext(CommonContext);

const filterPublished = ({ data }) =>
  data.filter(item => item.status === 'published');

const filterEvents = ({ data }) => {
  const currentDateTime = new Date();

  // Only present events should be show
  return data?.filter(event => {
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);
    return currentDateTime >= startDate && currentDateTime <= endDate;
  });
};

const CONSENT_MAP = {
  companyConsent: 'omo Agreement',
  gpoConsent: 'Purchasing Agreements',
  w9Consent: 'W9',
  ascConsent: 'ASC Consent',
};

export const CommonContextProvider = ({ children }) => {
  const { user } = useAuth();
  const [userTypes, setUserTypes] = useState(null);
  const [classOfTradeOptions, setClassOfTradeOptions] = useState(null);
  const [medicalDegrees, setMedicalDegrees] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [companySpecialty, setCompanySpecialty] = useState(null);
  const [businessEntityTypes, setBusinessEntityTypes] = useState(null);
  const [roles, setRoles] = useState(null);

  const [categories, setCategories] = useState(null);
  const [articles, setArticles] = useState(null);
  const [banners, setBanners] = useState(null);
  const [events, setEvents] = useState(null);
  const [pageBreakOrBanners, setPageBreakOrBanners] = useState(null);

  const [company, setCompany] = useState(null);
  const [consents, setConsents] = useState(null);
  const [articleFilter, setArticleFilter] = useState(null);

  const consentList = useMemo(
    function () {
      if (!consents) return null;

      const list = { ...consents };

      delete list.ascConsent;

      return Object.keys(list).map(consentType => ({
        type: consentType,
        title: CONSENT_MAP[consentType],
        list: list[consentType],
      }));
    },
    [consents],
  );

  const PHYSICIAN_TYPE_ID = useMemo(
    () => userTypes?.find(({ type }) => type === 'physician')?.id ?? 0,
    [userTypes],
  );

  const OTHER_TYPE_ID = useMemo(
    () => userTypes?.find(({ type }) => type === 'other')?.id ?? 0,
    [userTypes],
  );

  const isProspect = useMemo(() => !company?.isConsent, [company?.isConsent]);

  const getConsents = useCallback(
    function (companyId) {
      if (companyId || company?.companyId) {
        setConsents(null);
        getSignedConsentList(companyId || company?.companyId).then(setConsents);
      }
    },
    [company?.companyId],
  );

  useEffect(() => {
    if (user) {
      getUserTypesMasterList().then(setUserTypes);
      getClassOfTradeOptions().then(setClassOfTradeOptions);
      getMedicalDegrees().then(setMedicalDegrees);
      getSpecialty().then(setSpecialty);
      getCompanySpecialty().then(setCompanySpecialty);
      getBusinessEntityTypes().then(setBusinessEntityTypes);
      getUserRoles().then(setRoles);
      getOnBoardedCompany(user.companyId).then(setCompany);
      getConsents(user.companyId);

      if ((isMember(user) || isMemberAdmin(user)) && isActive(user.company)) {
        getCategoriesList().then(filterPublished).then(setCategories);
        getArticleList().then(filterPublished).then(setArticles);
        getEventList().then(filterEvents).then(setEvents);
        getBanners().then(filterPublished).then(setBanners);
        getPageBreakOrBanners()
          .then(filterPublished)
          .then(setPageBreakOrBanners);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <CommonContext.Provider
      value={{
        userTypes,
        classOfTradeOptions,
        medicalDegrees,
        specialty,
        companySpecialty,
        businessEntityTypes,
        roles,
        articleFilter,
        PHYSICIAN_TYPE_ID,
        OTHER_TYPE_ID,

        isProspect,
        company,
        consents,
        consentList,

        // Directus
        categories,
        articles,
        events,
        banners,
        pageBreakOrBanners,

        // methods
        setCompany,
        getConsents,
        setArticleFilter,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

CommonContextProvider.propTypes = {
  children: propTypes.node,
};
