import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  InputBase,
  Stack,
  SvgIcon,
  useMediaQuery,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';

import { AccountButton } from '../account-button';

import { useAuth } from 'src/hooks/use-auth';
import { useCommonContext } from 'src/contexts/common';

import { isMember, isMemberAdmin } from 'src/utils/common';
import { paths } from 'src/paths';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      border: '1px solid #d9d9d9',
      borderRadius: 4,
      minWidth: '10rem',
      width: '20%',
      '&:focus': {
        width: '100%',
        borderColor: '#bbb',
      },
      '&:hover': {
        borderColor: '#ccc',
      },
    },
  },
}));

export const TopNav = function ({ onMobileNavOpen, ...other }) {
  const { user } = useAuth();
  const location = useLocation();
  const { setArticleFilter, articleFilter } = useCommonContext();

  const showSearchBar = useMemo(
    () =>
      (isMember(user) || isMemberAdmin(user)) &&
      location.pathname === paths.dashboard.index,
    [location.pathname, user],
  );

  const handleOnChangeArticleFilter = useCallback(
    e => setArticleFilter(e.target.value),
    [setArticleFilter],
  );

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: theme => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: theme => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp ? (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          ) : null}
        </Stack>

        {showSearchBar ? (
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search articles..."
                inputProps={{ 'aria-label': 'search' }}
                value={articleFilter ?? ''}
                onChange={handleOnChangeArticleFilter}
              />
            </Search>
          </Box>
        ) : null}

        <AccountButton />
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
