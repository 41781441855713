import { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AUTH_STORAGE_KEY } from 'src/config.js';
import { getUserInfo, login, register } from 'src/api/auth';
import { AuthContext, initialState } from 'src/contexts/auth/jwt/auth-context';
import { validateAuth0TokenExpiryAndGetUserInfo } from 'src/utils/jwt.js';
import { signupViaMemberIntake } from 'src/api/onboarding.js';
import {
  addAskAnythingEmbedCode,
  isMember,
  isMemberAdmin,
} from '../../../utils/common.js';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_UP'] = 'SIGN_UP';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
  ActionType['SET_ERROR'] = 'SET_ERROR';
})(ActionType || (ActionType = {}));

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: state => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  SET_ERROR: (state, action) => ({
    ...state,
    error: action.payload,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      let auth = localStorage.getItem(AUTH_STORAGE_KEY);

      if (auth) {
        auth = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY));

        // 1. validate access token
        validateAuth0TokenExpiryAndGetUserInfo(auth.accessToken);

        // 2. once access token is validated fetch the user info from the api
        getUserInfo().then(user => {
          // persist new `userInfo` in the `localStorage`
          localStorage.setItem(
            AUTH_STORAGE_KEY,
            JSON.stringify({ ...auth, userInfo: user }),
          );

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          error: error.message,
        },
      });
    }
  }, [dispatch]);

  const signIn = useCallback(
    async (email, password) => {
      const { userInfo } = await login({ email, password });

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user: userInfo,
        },
      });
    },
    [dispatch],
  );

  const signUp = useCallback(
    async values => {
      const { userInfo } = await register(values);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user: userInfo,
        },
      });
    },
    [dispatch],
  );

  const signUpWithToken = useCallback(
    async values => {
      const { userInfo } = await signupViaMemberIntake(values);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user: userInfo,
        },
      });
    },
    [dispatch],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const setError = useCallback(
    error =>
      dispatch({
        type: ActionType.SET_ERROR,
        payload: error,
      }),
    [dispatch],
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    function () {
      if (isMember(state.user) || isMemberAdmin(state.user)) {
        addAskAnythingEmbedCode();
      }
    },
    [state.user],
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signUpWithToken,
        signOut,
        setError,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
