import { del, get, post, put } from 'src/api';

const ENDPOINT = 'company/onboard';

export const verifyLeadIntake = (companyId, token) =>
  get(`${ENDPOINT}/member-intake-verify/${companyId}/${token}`);
export const signupViaMemberIntake = values =>
  post(`${ENDPOINT}/member-intake`, values);

export const getOnBoardedCompany = id => get(`${ENDPOINT}/company/${id}`);
export const onboardCompany = (id, values) =>
  post(`${ENDPOINT}/company/${id}`, values);

export const updateCompanyDetails = (id, values) =>
  put(`${ENDPOINT}/company/${id}`, values);

export const getMembers = id => get(`${ENDPOINT}/memberList/${id}`);
export const inviteMember = values => post(`${ENDPOINT}/member-invite`, values);
export const updateMember = (id, values) =>
  put(`${ENDPOINT}/member-invite/${id}`, values);
export const deleteMember = id => del(`${ENDPOINT}/member-invite/${id}`);

export const getLocations = id => get(`${ENDPOINT}/locations/${id}`);
export const addLocation = values => post(`${ENDPOINT}/locations`, values);
export const updateLocation = (id, values) =>
  put(`${ENDPOINT}/locations/${id}`, values);
export const deleteLocation = id => del(`${ENDPOINT}/locations/${id}`);

export const subscribe = values => post(`${ENDPOINT}/subscription`, values);
export const onSubscriptionSuccess = values =>
  post(`${ENDPOINT}/subscription/success`, values);

export const getSignedConsentList = companyId =>
  get(`${ENDPOINT}/signed-consent/${companyId}`);
